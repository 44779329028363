.header {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 120px;
  width: 100vw;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  color: #fff;
}
.navs {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
}
.navs .nav {
  margin-left: 48px;
  text-decoration: none;
  transition: all 500ms;
  cursor: pointer;
}
.navs .nav:link {
  color: inherit;
}
.navs .nav:hover {
  color: inherit;
}
.navs .nav:visited {
  color: inherit;
}
.navs .nav.current {
  position: relative;
  color: #fff;
}
.navs .nav.current::after {
  content: '';
  position: absolute;
  bottom: -6px;
  right: 0;
  width: 100%;
  height: 2px;
  background: #FFFFFF;
  border-radius: 1px;
}
.scrollNavs {
  color: rgba(0, 0, 0, 0.5);
}
.scrollNavs .nav {
  color: inherit;
}
.scrollNavs .nav.current {
  color: #ee8f2e;
}
.scrollNavs .nav.current::after {
  background: #ee8f2e;
}
.page {
  height: 100vh;
  background-size: cover; 
  box-sizing: border-box;
}
.index {
  height: 100vh;
  background-size: cover; 
  box-sizing: border-box;
  background-image: url(./assets/index.png);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.slogan {
  height: 98px;
  font-size: 70px;
  font-family: PingFangTC-Semibold, PingFangTC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 98px;
  letter-spacing: 1px;
}
.index .sloganEnglish {
  height: 35px;
  font-size: 24px;
  font-family: SourceHanSansSC-Medium, SourceHanSansSC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 35px;
  letter-spacing: 1px;
}
.page, .about, .title, .business {
  position: relative;
}

.about {
  height: 100vh;
  background-size: cover; 
  box-sizing: border-box;
  background-image: url(./assets/about.png);
}

.about .description {
  transition: all 300ms ease;
  cursor: pointer;
  width: 800px;
  box-sizing: border-box;
  padding: 25px 33px;
  box-shadow: 0px 20px 30px 0px rgba(25, 51, 85, 0.05);
  border-radius: 6px;
  border: 1px solid rgba(20, 49, 119, 0.2);
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1A2B61;
  line-height: 32px;
  text-align: left;
  margin: 0 auto;
  margin-top: 36px;
}
.about .description .finance {
    font-weight: bold;
    margin-top: 36px;
}

.description:hover {
  box-shadow: 0px 20px 30px 0px rgba(25, 51, 85, 0.08);
  border: 1px solid #F79800;
}

.productPage {
  background-image: url(./assets/product.png);
}

.products {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 38px;
  width: 760px;
}

.product {
  position: relative;
  width: 360px;
  height: 420px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid rgba(20, 49, 119, 0.1);
  padding: 40px 45px 0;
  text-align: left;
  transition: all 300ms ease;
  cursor: pointer;
}

.product:hover {
  box-shadow: 0px 20px 30px 0px rgba(25, 51, 85, 0.08);
  border: 1px solid #ee8f2e;
}

.name {
  height: 32px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1A2B61;
  line-height: 32px;
  text-align: center;
  margin-bottom: 24px;
}

.desc {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1A2B61;
  line-height: 32px;
  width: 270px;
}


.title {
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1A2B61;
  line-height: 45px;
  text-align: center;
}

.business {
  text-align: center;
  height: 100vh;
  background-size: cover; 
  box-sizing: border-box;
  background-image: url(./assets/business-bg.png);
}
.business .businessImg {
  width: 823px;
  height: 419px;
  margin-top: 38px;
}
.phoneCode {
  width: 230px;
  height: 184px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(./assets/phoneCode.png);
}

.team {
  background-image: url(./assets/team.png);
}

.advantages {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 60px;
  width: 1200px;
}

.advantage {
  cursor: pointer;
  width: 380px;
  height: 360px;
  box-sizing: border-box;
  box-shadow: 0px 20px 30px 0px rgba(25, 51, 85, 0.05);
  border-radius: 4px;
  border: 1px solid rgba(20, 49, 119, 0.2);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 58px 0;
  transition: all 300ms ease;
}

.advantage:hover {
  transform: scale(1.03);
  box-shadow: 0px 20px 30px 0px rgba(25, 51, 85, 0.08);
  border: 1px solid #F79800;
}

.advantage img {
  height: 70px;
  width: auto;
}

.advantage .title {
  height: 32px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1A2B61;
  line-height: 32px;
  margin-bottom: 12px;
  margin-top: 26px;
}

.advantage .desc {
  height: 96px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1A2B61;
  line-height: 32px;
  text-align: left;
}

.contactPage {
  height: 100vh;
  background-size: cover; 
  box-sizing: border-box;
  background-image: url(./assets/contact.png);
  position: relative;
}

.cardWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
}

.card {
  width: 445px;
  height: 399px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 20px 30px 0px rgba(25, 51, 85, 0.05);
  border-radius: 4px;
  border: 1px solid rgba(20, 49, 119, 0.2);
  box-sizing: border-box;
  padding: 36px 30px 0;
}

.companyName {
  height: 32px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1A2B61;
  line-height: 32px;
  margin-bottom: 30px;
}

.contract {
  display: flex;
  margin-bottom: 27px;
}

.label {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(26, 43, 97, 0.6);
}

.value {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1A2B61;
}

.codes {
  display: flex;
  justify-content: center;
  text-align: center;
  color: rgba(26, 43, 97, 0.6);
  margin-top: 33px;
}

.address {
  width: 515px;
  height: 373px;
  border-radius: 00px 10px 10px 0;
}

.copyright {
  color: #999;
  position: absolute;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 15px;
  bottom: 24px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.amache {
  animation-delay: 0.5s;
}

