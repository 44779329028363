html, body, #root {
  height: 100%;
}
@font-face{
  font-family: 'PingFangSC-Regular';
  src : url('https://image.tiaozaoj.com/PingFang-SC-Regular.ttf');
}

body {
  margin: 0;
}

